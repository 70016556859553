<template>
  <div class="prize-page">
    <div class="prize-page-image">
      <img
      src="//download-cos.yofish.com/blind-gongjushiyebu/20220729092328814-prize-home.png"
      alt="">
    </div>
    <div class="prize-row">
      <template v-if="prizeList.length">
        <div class="prize-list"
          v-for="(item, index) in prizeList"
          :key="index" :class="{ 'prize-2': index === 1 }"
          @click="clickPrize(item)">
          <div class="prize-image">
            <img :src="item.imageUrl" alt="">
          </div>
          <div class="prize-desc">
            <p class="name">{{item.name}}</p>
            <p class="time">获得时间:{{item.time}}</p>
            <p class="desc" v-if="item.desc">{{item.desc}}</p>
          </div>
          <div class="btn-group" @click="openDialog(item, 'exchange')"
              v-if="item.type !== 3 &&
                (!item.exchangeStatus || item.exchangeStatus === 2)">去兑换</div>
          <div class="btn-group" @click="openDialog(item, 'writeAddress')"
            v-if="item.type === 3 && !item.exchangeStatus && !item.winnerAddress">填写地址</div>
          <div class="btn-group" @click="openDialog(item, 'getAddress')"
            v-if="item.type === 3 && !!item.winnerAddress && !item.exchangeStatus">查看地址</div>
          <div class="label" v-if="item.exchangeStatus === 1">
            <img
              src="//download-cos.yofish.com/blind-gongjushiyebu/20220729100254856-label.png"
              alt="">
          </div>
        </div>
      </template>
      <div class="no-prize" v-if="!prizeList.length && !pageLoading">
        <img
        src="//download-cos.yofish.com/blind-gongjushiyebu/20220729101123624-no-prize.png" alt="">
      </div>
      <div class="page-loading" v-if="!prizeList.length && pageLoading">
        <div class="loading-icon">
          <div class="icon">
            <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAAA8CAMAAADv/jI5AAAApVBMVEUAA
            AAA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9s
            A/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9
            sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9sA/9uy6DtYAAAANnRSTlMABPXy+e7e6jUoCOSemBTURrOrw
            6aRPSO9LhkLz6NQTDkfuK6MQvvo2WZZDsd8d8yGg2tyYVQNVljoAAADSklEQVRIx51X15aqQBAcchAkByVKUEHF
            7P9/2p0elrvrgi5YTx6gOlb3jGgAVLAqb2clzyzLSSk0BUv94piWNuMYluVP3ngiJcVnY/b4j6M7mrlMVJ59fIOL
            Ft27uZh67xJwVY3raML2uPeLNOgyuRzDKHnJXFUG0/J4Xzld6nusS6hDrT0erH+VhuNdnPnWYWg3iSf/en0R4N3G
            EakBamrT8JbVHH0Z9D8QI/KazkuqRy1zhhhWk+GKyKLDE3KWBL+obs6CU+u6QK8gXXzi2tefjYvKGj9ljPitjHSb
            dMF0n6Tk0ECN/lAgtVAFiM6WfuRy2BAdiOgvtGTGmXcPglIDa+YY+YkKRDi77zpjEZTYStAYuAb4Mb78UBUHpmp5
            nOQTHjrVyG0YJogJUhiF3XlGGoUAzQYiFtFYSBA1V4DjFXF7ktFoNMSxhItch/hXno5mQm1h1Ko5mjscrtthvFso
            Lo2rZXtIsrCR7R1NgR5iBWsp0nlsQ1lM4koqg0tUowuuMnedT+LubrhazAEVHNYFjM8UuBoEi2AdaCKaBs8CXaJs
            TXo1DUsTs/ZoDxPkTeTOoVgaCnHWymoqtxCwOhDOmlMnc0+bz7kFcD+M2RGwGD+sFWyeEGXsJz3KcY+sVhvpRK4E
            0eafabLcYk2q7Sw0E2ehIrNATLCR+MEM3pHnw+zX02c/FLudE0ygyhe6FYUcH7Fjw52y62wI9bbDvbLJug0m7tiM
            6OkKP62UGu3W+F7oJARBXY5t0Im4/UryJsBF4zYuajnm4QS+yl/tUuAM3cfj+pORs1rsTLlQ6rWhj6CmNgsHyvdd
            hyLlYkx3xLEP+Qnn3c89AFHTZim/ZVKiwsF3z7tiobLk3lXL76hJe3+z01/B2DTkfDyIryfgarFANUqqd50kNoXo
            Lg2qxItVciWnDb0fG+QC2KrxavdMp3ZL3dHWj7agQ6alw5aQma1xjqXvSgZScjC11vDstBjW7rI24Qv4ZG86TYUv
            3/e6ahzbAimReCvvdfuKI/Svdc9re2sf8lz3gAvV8u3i1QuNYR99rGleTf4YF2qlNznfo/LGoXcnH/Tt3k5RduQ3
            HM3SjMCHvl1U5Xuf/f90vuVnuXKudC8Y0ts/cNyMMH3dCB8AAAAASUVORK5CYII=" alt="">
          </div>
          <div class="loading-text">
            <img src="./image/loading-text.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <address-model :visible="visible" :is-address="isAddress"
      @submit="submitAddressData"
      :prize-data="prizeData"
      :address-data="addressData"
      @close="closeDialog"></address-model>
    <y-dialog :visible="prizeVisible" :appendToBody="true" class="prize-dialog-data">
      <div class="activity-dialog">
        <h1 class="title">{{ dialogTitle }}</h1>
        <p class="content">请于活动期间内兑换，超过有效期未兑换的视为主动放弃</p>
      </div>
      <div class="dialog-btns flex" slot="footer">
         <div class="btn default" @click="prizeVisible = false">取 消</div>
        <div class="btn primary" @click="okAction">{{ okBtnText }}</div>
      </div>
    </y-dialog>
    <y-loading v-if="isLoading" :appendToBody="true" />
  </div>
</template>
<script>

import {
  getMyBlindboxPrizeApi,
  saveAddressBlindboxApi,
  exchangePrizeBlindboxApi,
} from '@/api/base1';
import {
  isArray, getNewDayYMDHMS, changeParam,
  debounce,
} from '@/utils';
import AddressModel from './components/address-prize-model';
import YDialog from '@/components/dialog';
import YLoading from '@/components/loading';
import { getAppStatus } from '@/utils/app';

export default {
  name: 'BlindPrize',
  data() {
    return {
      prizeList: [
      ],
      visible: false,
      isAddress: false,
      prizeVisible: false,
      prizeData: {},
      dialogTitle: '',
      winningPrizeId: '',
      addressData: null,
      okBtnText: '我知道了',
      prizeType: '',
      wH: 0,
      pageLoading: true,
      submiting: false,
      isLoading: false,
    };
  },
  components: {
    AddressModel,
    YDialog,
    YLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    loading(val) {
      val && this.getMyPrizeList();
    },
  },
  computed: {
    cuserId() {
      // return '217e4255-b424-4c96-bbce-d01d24c82353';
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      // return 'd6d2544a-52df-4192-8003-2d60ee24cda3';
      // return '69000cdb-9989-4486-82f7-f214af4123ee';
      return getAppStatus().cuserId;
    },
    isIos() {
      return /i(os|phone|pad)/i.test(navigator.userAgent);
    },
    isAndroid() {
      return /android/i.test(navigator.userAgent);
    },
    toastStyle() {
      return {
        backgroundColor: '#18695c',
      };
    },
    activityId() {
      return 2;
    },
  },
  mounted() {
    this.getMyPrizeList();
    // this.$parent.disabledScroll = true;
    this.$nextTick((_) => {
      this.wH = document.body.clientHeight;
    });
  },
  methods: {
    async getMyPrizeList(prizeType, jumpUrl) {
      let res;
      const { cuserId, isIos, activityId } = this;
      this.isLoading = !!this.prizeList.length;
      try {
        res = await getMyBlindboxPrizeApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.pageLoading = false;
        return this.$toast(res?.desc || '网络异常');
      }
      this.isLoading = false;
      this.pageLoading = false;
      if (res?.code === 1) {
        let prizeList = isArray(res?.results) ? res.results : [];
        prizeList = prizeList.map((item) => ({ ...item, time: getNewDayYMDHMS(item.createTime) }));
        this.prizeList = prizeList;
        prizeList.forEach((item) => {
          let { exchangeStatus, type } = item;
          if (type === 0 && exchangeStatus === 1) {
            item.appUrl = isIos ? 'yyjz://wallet/index' : 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.mineWallet.WalletActivity';
          }
          if (type === 2 && exchangeStatus === 1) {
            item.appUrl = isIos ? 'yyjz://app/coupon' : 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.coupon.activity.CouponListActivity';
          }
        });
        if (prizeType === 1 && jumpUrl) {
          window.open(jumpUrl || '', '_self');
        }
      }
    },
    async submitAddressData(data) {
      let res;
      const { cuserId } = this;
      data.cuserid = cuserId;
      try {
        res = await saveAddressBlindboxApi(data);
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        this.visible = false;
        this.$toast({
          content: '地址填写成功',
          style: this.toastStyle,
        });
        this.getMyPrizeList();
      } else {
        this.visible = false;
        this.$toast({ content: res?.desc || '网络异常' });
      }
    },
    closeDialog() {
      this.visible = false;
    },
    openDialog: debounce(function (data, type) {
      this.prizeData = data;
      if (type === 'writeAddress') {
        this.isAddress = false;
        this.visible = true;
      }
      if (type === 'exchange') {
        this.okBtnText = '立即兑换';
        this.prizeVisible = true;
        this.dialogTitle = data.name;
        this.winningPrizeId = data.id;
      }
      if (type === 'getAddress') {
        this.addressData = data.winnerAddress;
        this.isAddress = true;
        this.visible = true;
      }
    }, 600, true),
    okAction: debounce(async function () {
      let res;
      if (this.submiting) return false;
      this.submiting = true;
      const { cuserId, winningPrizeId, activityId } = this;
      let data = { cuserId, winningPrizeId, activityId };
      data = changeParam(data);
      let appInfo;
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        appInfo = window.ios.jzAppInfo();
      } else if (/android/i.test(navigator.userAgent)) {
        appInfo = window.android.jzAppInfo();
        this.type = 2;
      } else {
        appInfo = {
          name: 'com.jz.youyu',
          channel: '官网',
          version: '5.1.0',
          osType: 1,
        };
        appInfo = JSON.stringify(appInfo);
      }
      appInfo = JSON.parse(appInfo);
      const header = {
        name: encodeURI(appInfo.name),
        osType: appInfo.osType,
        channel: encodeURI(appInfo.channel),
        releaseVersion: appInfo.version,
      };
      try {
        res = await exchangePrizeBlindboxApi({}, data, header);
      } catch (e) {
        console.log(e);
        this.submiting = false;
      }
      if (res?.code === 1) {
        // type !== 1 && this.getMyPrizeList();
        this.prizeVisible = false;
        const { type } = this.prizeData;
        if (type !== 1) {
          type !== 1 && this.$toast({
            content: '兑换成功',
            style: this.toastStyle,
          });
          this.getMyPrizeList();
        }
        if (type === 1) {
          let url = decodeURIComponent(res?.results);
          this.getMyPrizeList(type, url);
          // window.location.href = url;
          // window.open(url || '', '_self');
        }
        this.submiting = false;
        // let url = decodeURIComponent(res?.results);
        // type === 1 && window.open(url || '', '_self');
      } else {
        this.submiting = false;
        this.$toast({
          content: res?.desc || '兑换失败',
          style: this.toastStyle,
        });
      }
    }, 600, true),
    async okAction1() {
      let res;
      if (this.submiting) return false;
      this.submiting = true;
      const { cuserId, winningPrizeId } = this;
      let data = { cuserId, winningPrizeId, activityId: 1 };
      data = changeParam(data);
      let appInfo;
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        appInfo = window.ios.jzAppInfo();
      } else if (/android/i.test(navigator.userAgent)) {
        appInfo = window.android.jzAppInfo();
        this.type = 2;
      } else {
        appInfo = {
          name: 'com.jz.youyu',
          channel: '官网',
          version: '5.1.0',
          osType: 1,
        };
        appInfo = JSON.stringify(appInfo);
      }
      appInfo = JSON.parse(appInfo);
      const header = {
        name: encodeURI(appInfo.name),
        osType: appInfo.osType,
        channel: encodeURI(appInfo.channel),
        releaseVersion: appInfo.version,
      };
      try {
        res = await exchangePrizeBlindboxApi({}, data, header);
      } catch (e) {
        console.log(e);
        this.submiting = false;
      }
      if (res?.code === 1) {
        // type !== 1 && this.getMyPrizeList();
        // this.prizeVisible = false;
        const { type } = this.prizeData;
        if (type !== 1) {
          type !== 1 && this.$toast({
            content: '兑换成功',
            style: this.toastStyle,
          });
          this.getMyPrizeList();
        }
        if (type === 1) {
          let url = decodeURIComponent(res?.results);
          this.getMyPrizeList(type, url);
          // window.location.href = url;
          // window.open(url || '', '_self');
        }
        this.submiting = false;
        // let url = decodeURIComponent(res?.results);
        // type === 1 && window.open(url || '', '_self');
      } else {
        this.submiting = false;
        this.$toast({
          content: res?.desc || '兑换失败',
          style: this.toastStyle,
        });
      }
    },
    clickPrize(data) {
      const { appUrl } = data;
      console.log(appUrl);
      if (appUrl) {
        window?.android?.jumpRoute(appUrl);
        window?.ios?.jumpRoute(appUrl);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.visible = false;
    this.prizeVisible = false;
    window.setTimeout(() => {
      this.$destroy();
      next();
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
  .prize-page {
    width: 100%;
    min-height: 1537px;
    position: relative;
  }
  .prize-row {
    position: relative;
    width: 703px;
    margin: auto;
    padding-top: 70px;
    min-height: 800px;
    padding-bottom: 100px;
    border-top: none;
    .bg-top {
      width: 100%;
      height: 632px;
      background-size: cover;
      background-image: url('//download-cos.yofish.com/ad-gongjushiyebu/20220616113539812-1.png');
      .top {
        padding-top: 33px;
      }
      .prize-list:nth-child(3) {
        margin: 15px auto 0;
      }
    }
    .bg-center {
      width: 100%;
      height: 600px;
      background-size: cover;
      background-image: url('//download-cos.yofish.com/ad-gongjushiyebu/20220616113556399-2.png');
      .top {
        padding-top: 30px;
      }
      .prize-list:nth-child(2) {
        margin: auto;
      }
    }
    .bg-bottom {
      width: 100%;
      height: 466px;
      background-image: url('//download-cos.yofish.com/ad-gongjushiyebu/20220616113616236-3.png');
      background-size: cover;
      .top {
        padding-top: 15px;
      }
      .prize-1 {
        margin-top: 0;
      }
    }
  }
  .prize-list {
    display: flex;
    align-items: center;
    margin: 0 50px 30px 50px;
    min-height: 185px;
    background: #FFFFFF;
    border: 2px solid #00FFD6;
    border-radius: 6px;
    .mask {
      width: 100%;
      height: 164px;
      background: #8F7878;
      opacity: 0.6;
      position: absolute;
      left: 0;
      top: 10px;
      background: linear-gradient(-45deg, transparent 15px, #8F7878 0) right;
      z-index: 10;
    }
    img {
      width: 100%;
    }
    .label {
      width: 102px;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  .prize-top {
    width: 704.4px;
    height: 61px;
    position: absolute;
    left: -1px;
    top: -61px;
    background-image: url('//download-cos.yofish.com/ad-gongjushiyebu/20220616162952736-top.png');
    background-size: cover;
  }
  .prize-bottom {
    width: 705px;
    height: 61px;
    position: absolute;
    left: -1px;
    bottom: -61px;
    background-image:
      url('//download-cos.yofish.com/ad-gongjushiyebu/20220616162904674-bottom.png');
    background-size: cover;
  }
  .prize-image {
    width: 185px;
    text-align: center;
    position: relative;
    background: rgba(34,255,219,0.1000);
    border-radius: 6px;
    img {
      width: 100%;
    }
  }
  .prize-desc {
    margin-left: 22px;
    > p.name {
      font-size: 26px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #000000;
      margin-bottom: 20px;
    }
    > p.time, > p.desc {
      font-size: 20px;
      font-family: OPPOSans;
      font-weight: 400;
      color: #666666;
    }
  }
  .btn-group {
    flex: 0 0 auto;
    position: relative;
    width: 134px;
    height: 56px;
    background-image:
    url('//download-cos.yofish.com/blind-gongjushiyebu/2022072816461843-btn.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 56px;
    margin-left: auto;
    font-size: 22px;
    font-family: OPPOSans;
    font-weight: 500;
    color: #29FFDD;
    margin-right: 20px;
  }
  .no-prize {
    width: 448px;
    margin: auto;
    padding-top: 106px;
    img {
      width: 100%;
    }
  }
  .prize-page-image {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    > img {
      width: 100%;
    }
  }
  /deep/ .y-dialog-main {
    width: 554px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .activity-dialog {
      text-align: center;
      margin: 0 31px;
      .title {
        margin-top: 32px;
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        line-height: 54px;
      }
      .content {
        margin-top: 24px;
        font-size: 28px;
        font-weight: 400;
        color: #666666;
        line-height: 54px;
      }
    }
    .dialog-btns {
      margin-top: 24px;
      text-align: center;
      border-top: 1PX solid #EAE7E7;
      .btn {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        color: #666666;
        padding: 31px 0;
        &.primary {
          font-weight: 500;
          color: #00FFDA;
        }
      }
    }
    .dialog-btns.flex {
      display: flex;
      .btn {
        flex: 1;
        &:last-child {
          border-left: 1PX solid #EAE7E7;
        }
      }
    }
   @media only screen and (min-width: 1720px) and (max-width: 434px) {
    .head-label .label-content {
      width: 1000px;
    }
  }
  .page-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-icon {
      text-align: center;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FA4983;
    }
   div.icon {
      display: block;
      width: 60px;
      height: 60px;
      margin: auto;
      img {
        width: 100%;
      }
      animation: rotating 2s linear infinite;
    }
    .loading-text {
      width: 171px;
      margin-top: 27px;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
  @keyframes rotating {
    0% {
      transform: rotate(0deg)
    }

    to {
      transform: rotate(1turn)
    }
  }
</style>
<style>
.y-dialog__wrapper.prize-dialog-data {
  z-index: 100;
}
</style>
